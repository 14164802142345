import React from "react"
import {graphql, navigate} from "gatsby"

import Layout from "../components/layout"
import Meta from "../components/meta"

const NotFound = ({data, location}) => {
    return (
        <Layout location={location}>
            <Meta title="Page not found"/>
            <div className="request_demo">
                <div className="wrapper">
                    <div className="thankyou_wrap">
                        <h2>Page not found</h2>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default NotFound;